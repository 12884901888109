import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Deserialize, IJsonObject } from 'dcerialize';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { Category, CategoryList } from '../models/category';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);

  path = '/categories';

  constructor() {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * GET all faculties
   * @returns CategoryList with the results of the request as an observable
   */
  listFaculties(): Observable<CategoryList> {
    return this.http
      .get<IJsonObject>(`${this.path}/faculties`)
      .pipe(map((facultyList) => Deserialize(facultyList, () => CategoryList)));
  }

  /**
   * GET all schools
   * @returns CategoryList with the results of the request as an observable
   */
  listSchools(): Observable<CategoryList> {
    return this.http
      .get<IJsonObject>(`${this.path}/schools`)
      .pipe(map((schoolList) => Deserialize(schoolList, () => CategoryList)));
  }

  /**
   * GET all course types
   * @returns CategoryList with the results of the request as an observable
   */
  listTypes(): Observable<CategoryList> {
    return this.http
      .get<IJsonObject>(`${this.path}/types`)
      .pipe(map((schoolList) => Deserialize(schoolList, () => CategoryList)));
  }

  /**
   * Get category by id param
   * @param id of the category
   */
  get(id: number): Observable<Category> {
    return this.http
      .get<IJsonObject>(`${this.path}/${id}`)
      .pipe(map((category) => Deserialize(category, () => Category)));
  }

  /**
   * Get the list of areas of a given category ID
   */
  getAreasFromFaculty(id: number): Observable<CategoryList> {
    return this.http
      .get<IJsonObject>(`${this.path}/${id}/areas`)
      .pipe(map((categoryList) => Deserialize(categoryList, () => CategoryList)));
  }

  /**
   * Get categories by euroinnova id
   * @param id of the euroinnova
   */
  getCategoriesByInnovaId(id: number): Observable<Category> {
    return this.http
      .get<IJsonObject>(`${this.path}/euroinnova/${id}`)
      .pipe(map((category) => Deserialize(category, () => Category)));
  }

  /**
   * Get the categories from the free courses
   */
  getCategoriesFromFreeCourses(): Observable<CategoryList> {
    return this.http
      .get<IJsonObject>(`${this.path}/from-free-courses`)
      .pipe(map((categoryList) => Deserialize(categoryList, () => CategoryList)));
  }
}
