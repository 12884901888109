import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { RestItemList } from './rest-item';

export class Category {
  /**
   * ID
   */
  @autoserializeAs(() => Number) _id: number;

  /**
   * Parent id
   */
  @autoserializeAs(() => Number) parentId: number | null;

  /**
   * Description
   */
  @autoserializeAs(() => String) description: string;

  /**
   * Category name
   */
  @autoserializeAs(() => Number) euroinnovaId: number;

  /**
   * Category label
   */
  @autoserializeAs(() => String) label: string;

  /**
   * Category icon name
   */
  @autoserializeAs(() => String) icon: string;

  /**
   * Category machine name
   */
  @autoserializeAs(() => String) machineName: string;

  /**
   * Category type id
   */
  @autoserializeAs(() => Number) type: number;

  /**
   * Category active
   */
  @autoserializeAs(() => Boolean) active: boolean;

  static get schoolType(): number {
    return 12;
  }
  constructor(
    id: number,
    parentId: number | null,
    description: string,
    label: string,
    icon: string,
    machineName: string,
    type: number,
    active: boolean,
    euroinnovaId: number
  ) {
    this._id = id;
    this.parentId = parentId;
    this.description = description;
    this.label = label;
    this.icon = icon;
    this.machineName = machineName;
    this.type = type;
    this.active = active;
    this.euroinnovaId = euroinnovaId;
  }
  public isFaculty(): boolean {
    return !this.parentId && this.type !== Category.schoolType;
  }
}

/**
 * Class that represents a Category list response
 */
export class CategoryList extends RestItemList<Category> {
  /**
   * Category list
   */
  @autoserializeAsArray(() => Category) items: Category[];

  /**
   * Number of categories
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: Category[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}
